var site = site || {};

(function ($) {
  Drupal.behaviors.el_lyl_enrollment_v1 = {
    attach: function (context) {
      var overlay = {};
      var isSignedIn = +site.userInfoCookie.getValue('signed_in');
      var isLoyalty = +site.userInfoCookie.getValue('is_loyalty_member');
      var colorboxParam = $('.page-navigation', context).offset();
      var colorboxParamWidth =
        $('.page-utilities__search', context).width() + $('.page-utilities__cart', context).width() + 20;
      var self = this;
      var colorboxParamTop = colorboxParam ? colorboxParam.top : '';

      overlay = {
        pc: {
          className:
            'loyalty-enrollment-overlay signin-overlay-wrapper signin-overlay-loyalty signin-overlay-loyalty--enrollment',
          width: '486px',
          height: '380px',
          fixed: true,
          transition: 'none',
          left: 0,
          right: colorboxParamWidth + 45,
          top: colorboxParamTop
        },
        mobile: {
          className:
            'loyalty-enrollment-overlay signin-overlay-wrapper signin-overlay-loyalty signin-overlay-loyalty--enrollment',
          width: '90%',
          height: '505px',
          transition: 'none'
        }
      };
      if (!Drupal.settings.globals_variables.my_feed_loyalty_enroll_url) {
        $('.user-logged-out__link', context).on('click', function (e) {
          e.preventDefault();
          if (isSignedIn && !isLoyalty) {
            site.track.loyaltyTag('enrollment overlay');
          }
          self.renderOverlayPage('loyalty_enrollment_popup', overlay);
        });
      }
    },
    openWindow: function (content, callBacks, overlayObj) {
      var self = Drupal.behaviors.ELB_loyalty_offer,
        args,
        darg,
        overlay;

      overlay = self.isObj(overlayObj) ? overlayObj : self.overlay;
      args = { html: content };
      darg = $(window).width() < 1024 ? overlay.mobile : overlay.pc;
      $.extend(args, darg);
      if (self.isObj(callBacks)) {
        for (var k in callBacks) {
          var func = callBacks[k];

          if (self.isFunc(func)) {
            args[k] = func;
          }
        }
      }
      $.colorbox(args);
    },
    renderOverlayPage: function (page, overlayObj) {
      var content = Drupal.behaviors.ELB_loyalty_offer.getTemplateContent(page);
      var self = this;

      self.openWindow(
        content,
        {
          onOpen: function () {
            $('.welcome15-overlay #cboxContent,#cboxOverlay').css('visibility', 'hidden');
          }
        },
        overlayObj
      );
    }
  };
})(jQuery);
